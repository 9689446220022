<template>
  <div class="moder-reviews-wrapper">
    <h3 class="moder-reviews-title h3 text-center">
      Модерация отзывов
      <a @click="runExport()" class="btn btn-light border">
        <template v-if="!exportLoading && (reviews ? reviews.length > 0 : false)">Скачать</template>
        <template v-else>
          <b-icon
            icon="arrow-clockwise"
            animation="spin-pulse"
            font-scale="4"
            style="width: 18px; height: 18px"
          ></b-icon>
        </template>
      </a>
    </h3>
    <div class="moder-reviews-controls">
      <b-form
        inline
        class="sm-mb-2 search-form"
        @submit.prevent="
          () => {
            page = 1;
            load();
          }
        "
      >
        <b-input-group>
          <b-form-input
            id="search-input"
            v-model="search"
            type="text"
            placeholder="Поиск"
          ></b-form-input>
          <b-input-group-append class="border-left border-light">
            <b-button variant="info" type="submit">
              <b-icon icon="search"></b-icon>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form>
      <div>
        <span>Одобренных: </span>
        <span style="font-weight: bold; color: green">{{ acceptedRevs }} </span>
        <span> Отклоненных: </span>
        <span style="font-weight: bold; color: red">{{ refusedRevs }}</span>
        <span> В общем: </span>
        <span style="font-weight: bold; color: gray">{{ refusedRevs + acceptedRevs }}</span>
      </div>
      <b-tabs v-model="reviewsMode" class="moder-reviews-tabs">
        <b-tab title="Свежие" />
        <b-tab title="Одобренные" />
        <b-tab title="Отклоненные" />
        <b-tab title="Заблокированные" />
        <b-tab title="Измененные" />
      </b-tabs>
    </div>
    <template v-if="reviews">
      <moder-review
        :key="review.id"
        :review="review"
        :collapsed="reviewsMode > 0"
        @processed="removeReview(review)"
        v-for="review in reviews"
      />
      <pagination
        v-if="totalPages > 0"
        :current="page"
        :total="totalPages"
        @page-change="pageChangeHandler"
      ></pagination>
    </template>
    <loader v-else />

    <review-solution v-if="this.$store.state.reviewsModeration.showSolutionPopup" />
    <moder-reviews-popup />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Loader from '@main/components/Loader.vue';
import moment from 'moment';
import ModerReview from './ModerReview.vue';
import ReviewSolution from './ModerReviewSolution.vue';
import ModerReviewsPopup from './ModerReviewsPopup.vue';
import Pagination from '../Pagination.vue';
import moderApi from '../../api/reviews-moderation';
import reviewsApi from '../../api/reviews';

export default {
  props: ['search', 'user'],
  components: {
    ModerReview,
    Pagination,
    Loader,
    ReviewSolution,
    ModerReviewsPopup,
  },
  watch: {
    async reviewsMode() {
      await this.load();
    },
    async exportId() {
      if (this.exportId) {
        const intervalId = setInterval(async () => {
          await moderApi.checkExport(this.exportId, (res) => {
            if (res.result_file) {
              window.location.replace(res.result_file);
              this.exportLoading = false;
              this.exportId = null;
              clearInterval(intervalId);
            }
          });
        }, 1000);
      }
    },
  },
  data: () => ({
    reviews: null,
    totalPages: 1,
    page: 1,
    reviewsMode: 0,
    exportLoading: false,
    exportId: null,
    acceptedRevs: 0,
    refusedRevs: 0,
  }),
  methods: {
    ...mapActions('reviewsModeration', ['loadRefuseReasons']),
    async load() {
      this.reviews = null;
      const params = {
        page: this.page,
        ordering: '-processed_at',
        search: this.search,
      };
      if (this.user) params.user = this.user;

      switch (this.reviewsMode) {
        case 0:
          params.moderation_status = this.reviewsOptions.groups.NEW_STATUSES;
          params.ordering = 'date';
          break;
        case 1:
          params.moderation_status = this.reviewsOptions.groups.ACCEPTED_STATUSES;
          break;
        case 2:
          params.moderation_status = this.reviewsOptions.groups.REFUSED_STATUSES;
          break;
        case 3:
          params.banned = true;
          break;
        case 4:
          params.moderation_status = this.reviewsOptions.groups.RESENT_STATUSES;
          break;
        default:
          break;
      }

      const res = await moderApi.list(params);
      this.totalPages = res.total_pages;
      this.acceptedRevs = res.accepted;
      this.refusedRevs = res.refused;
      this.reviews = res.results;
    },
    async pageChangeHandler(page) {
      this.page = page;
      await this.load();
    },
    async runExport() {
      await moderApi.export(this.reviewsMode, (res) => {
        this.exportId = res.id;
        this.exportLoading = true;
      });
    },
    async removeReview(review) {
      this.reviews = this.reviews.filter((x) => x.id !== review.id);
    },
  },
  computed: {
    ...mapState(['currentUser', 'reviewsOptions']),
  },
  async mounted() {
    await this.load();
    await this.loadRefuseReasons();
  },
};
</script>

<style scoped>
.moder-reviews-controls {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

@media (min-width: 1200px) {
  .moder-reviews-wrapper {
    width: 1110px;
    margin: auto;
  }

  .moder-reviews-controls {
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .moder-reviews-tabs {
    font-size: 14px;
  }

  .moder-reviews-tabs >>> .nav.nav-tabs {
    border: 0;
  }

  .moder-reviews-tabs >>> .nav-item {
    margin: 0;
    border: 1px solid #d5dfe4;
    border-radius: 0;
    box-shadow: none;
    background-color: #fff;
    color: #192229;
    margin: 0;
  }

  .moder-reviews-tabs >>> .nav-item a.nav-link {
    color: #192229;
    border-radius: 0;
    padding: 0.4rem 0.5rem;
  }

  .moder-reviews-tabs >>> .nav-item:not(:last-child) {
    border-right: 0;
  }

  .moder-reviews-tabs >>> .nav-item:first-child {
    border-radius: 5px 0 0 5px;
  }
  .moder-reviews-tabs >>> .nav-item:last-child {
    border-radius: 0 5px 5px 0;
  }

  .moder-reviews-tabs >>> .nav-tabs .nav-link.active {
    color: #fff;
    background-color: #3897f0;
    border-color: #3897f0 !important;
  }
}

@media (max-width: 1200px) {
  .moder-reviews-wrapper {
    padding-top: 50px;
  }

  .moder-reviews-controls {
    justify-content: center;
  }

  .moder-reviews-tabs >>> .nav.nav-tabs {
    position: fixed;
    top: 75px;
    width: 100vw;
    left: 0;
    z-index: 9;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 10px 10px 0 10px;
  }

  .moder-reviews-tabs >>> .nav.nav-tabs a.nav-link {
    font-size: 13px;
    padding: 0;
    color: #6a7b83;
    padding-bottom: 10px;
  }

  .moder-reviews-tabs >>> .nav-tabs .nav-link.active {
    color: inherit;
    background-color: inherit;
    border: 0;
    border-bottom: 2px solid black;
  }
}
</style>
